import React from 'react';
import logo from '../../static/RB-w.png';
import {Link} from 'gatsby';

const NavHeader: React.FC<{}> = (props) =>  {
  const navItems: {title: string, url: string}[][] = [
    [
      {title: "Home", url: "/"},
      {title: "Blog", url: "/blog"},
      {title: "Contact", url: "/contact"}
    ],
    [
      {title: "Twitter", url: "https://twitter.com/rikberkelder"},
      {title: "Mastodon", url: "https://dook.business/@RikSolo"}
    ],
    [
      {title: "RBLicht", url: "https://rblicht.nl"},
      {title: "RBSolutions", url: "https://rbsolutions.cc"}
    ]
  ]

  const rendered = navItems.map((item, index)=>{
    const renderedChild = item.map((child, index)=>{
      return (
        <>
          {child.url.startsWith('http') ? (
            <a
              href={child.url}
            >
              {child.title}
            </a>
          ): (
            <Link
              activeClassName="text-blue-300"
              partiallyActive={child.url !== "/"}
              to={child.url}
            >
            {child.title}
            </Link>
          )}
          {index < item.length - 1 ? <span className="mx-1.5 align-middle">-</span> :null}
        </>
      );
    })
    return (
      <>
        {renderedChild}
        <br className="md:hidden" />
        {index < navItems.length - 1 ? <span className="mx-2 hidden md:inline">|</span> : null}
      </>
    );
  });

  return (
    <>
      <a href="/" className="text-gray-100 no-underline">
        <img className="h-5 inline mr-2" src={logo}/>
        <span className="align-middle font-bold text-lg">
          Rik Berkelder
        </span>
      </a>

      <br className="md:hidden" />
      <span className="hidden md:inline mx-2 align-middle">|</span>
      <span className="align-middle">
        {rendered}
      </span>

      <hr className="border-gray-800 my-2"/>
	  </>
  );
};

export default NavHeader;
