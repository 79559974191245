import React from 'react';
import NavHeader from './NavHeader';
import {Helmet} from 'react-helmet';

const Layout: React.FC<{}> = (props) =>  {
    return (
	<div className="bg-gray-900 text-gray-200 min-h-screen min-w-screen font-main">
	  <Helmet
      defaultTitle="Rik Berkelder"
      titleTemplate="%s | Rik Berkelder"
    >
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta name="google-site-verification" content="x17arJy33V33DYnmMYybZciXngFd8R8xn1UZRdp7qxo" />
    </Helmet>
    <div className="mx-auto max-w-screen-lg container pt-1.5 p-0 sm:p-2">
    <NavHeader/>
		<main>
		{props.children}
		</main>
	    </div>
      {process.env.NODE_ENV === "production" ? (
      <script data-goatcounter="https://riksolo-com.stats.riksolo.com/count"
        async src="//riksolo-com.stats.riksolo.com/count.js"></script>
      ) : null}
	</div>
    )
};

export default Layout;
